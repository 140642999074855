import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiPage,
  EuiPageBody,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTextAlign,
  EuiTextColor,
  EuiTitle,
} from "@elastic/eui";
import { Employee } from "types/apiTypes";

const Contact: React.FC = () => {
  const data: any = {
    claims: [
      {
        displayName: "Marshall Hancock",
        emailAddress: "marsh.hancock@wcicny.com",
        phoneExtensions: "209",
        title: "Vice President & Chief Claims Officer",
      },
      {
        displayName: "Steve Ressue",
        emailAddress: "steve.ressue@wcicny.com",
        phoneExtensions: "210",
        title: "Property Claims Manager",
      },
      {
        displayName: "Mike Kallfelz",
        emailAddress: "mike.kallfelz@wcicny.com",
        phoneExtensions: "208",
        title: "Claims Adjuster",
      },
      {
        displayName: "Sherry Monroe-Seal",
        emailAddress: "sherry.monroe@wcicny.com",
        phoneExtensions: "201",
        title: "Inside Property Claims Rep. & Claims CSR",
      },
    ],
    it: [
      {
        displayName: "Bryan Rice",
        emailAddress: "bryan.rice@wcicny.com",
        phoneExtensions: "234",
        title: "Chief Technology Officer",
      },
      {
        displayName: "Brandon Helstrom",
        emailAddress: "brandon.helstrom@wcicny.com",
        phoneExtensions: "242",
        title: "Software Engineer",
      },
    ],
    accounting: [
      {
        displayName: "Harley Roberson",
        emailAddress: "harley.roberson@wcicny.com",
        employeeID: "E*85",
        phoneExtensions: "243",
        title: "Chief Accounting Officer",
      },
      {
        displayName: "Debbie McCartney",
        emailAddress: "debbie.mccartney@wcicny.com",
        phoneExtensions: "235",
        title: "Accounting Clerk",
      },
      {
        displayName: "Tina Macdonald",
        emailAddress: "tina.macdonald@wcicny.com",
        phoneExtensions: "244",
        title: "Accounting Clerk",
      },
    ],
    other: [
      {
        displayName: "Laura Harris",
        emailAddress: "laura.harris@wcicny.com",
        phoneExtensions: "241",
        title: "Chief Consumer Affairs Officer & Agency Marketing",
      },
      {
        displayName: "Kristen Rice",
        emailAddress: "kristen.rice@wcicny.com",
        phoneExtensions: "230",
        title: "Corporate Governance Specialist & Admin Assistant",
      },
    ],
    underwriting: {
      teamOne: [
        {
          displayName: "Sue Ferguson",
          emailAddress: "sue.ferguson@wcicny.com",
          phoneExtensions: "240",
          title: "Senior Underwriter",
        },
        {
          displayName: "Lliana Carson",
          emailAddress: "lliana.carson@wcicny.com",
          phoneExtensions: "206",
          title: "Underwriter Assistant",
        },
        {
          displayName: "Marylou Emmel",
          emailAddress: "marylou.emmel@wcicny.com",
          phoneExtensions: "213",
          title: "Underwriter Assistant",
        },
        {
          displayName: "Lisa Vann",
          emailAddress: "lisa.vann@wcicny.com",
          phoneExtensions: "202",
          title: "Policy Processor",
        },
        {
          displayName: "Zak Fremouw",
          emailAddress: "zak.fremouw@wcicny.com",
          phoneExtensions: "233",
          title: "Policy Processor",
        },
      ],
      teamTwo: [
        {
          displayName: "Kevin George",
          emailAddress: "kevin.george@wcicny.com",
          phoneExtensions: "218",
          title: "Senior Underwriter",
        },
        {
          displayName: "Amber Colegrove",
          emailAddress: "amber.colegrove@wcicny.com",
          phoneExtensions: "216",
          title: "Underwriter Assistant",
        },
        {
          displayName: "Emily Matteson",
          emailAddress: "emily.matteson@wcicny.com",
          phoneExtensions: "217",
          title: "Policy Processor",
        },
        {
          displayName: "Tamra Conolly",
          emailAddress: "tamra.conolly@wcicny.com",
          phoneExtensions: "212",
          title: "Policy Processor",
        },
      ],
      farmAndCommercial: [],
    },
    executive: [
      {
        displayName: "Jeff Rice",
        emailAddress: "jeff.rice@wcicny.com",
        phoneExtensions: "211",
        title: "President/CEO & Chief Investment Officer",
      },
    ],
  };

  const columns: EuiBasicTableColumn<Employee>[] = [
    {
      field: "displayName",
      name: "Name",
      width: "150px",
    },
    {
      field: "title",
      name: "Title",
      truncateText: true,
    },
    {
      field: "emailAddress",
      name: "Email",
      render: (email: string) => {
        return <EuiLink href={`mailto:` + email}>{email}</EuiLink>;
      },
    },
    {
      field: "phoneExtensions",
      name: "Phone",
      width: "75px",
      render: (phone: string) => {
        return <>{phone}</>;
        // return <EuiLink href={`tel:+13159232861`}>{phone}</EuiLink>;
      },
    },
  ];

  return (
    <EuiPage>
      <EuiPageBody restrictWidth="1300px">
        <EuiTitle>
          <h3>Contact Us</h3>
        </EuiTitle>
        <EuiSpacer />
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPanel>
              <EuiText size="s">
                <EuiTextAlign textAlign="left">Wayne Cooperative Insurance Company</EuiTextAlign>
                <EuiTextAlign textAlign="left">10267 Old Route 31</EuiTextAlign>
                <EuiTextAlign textAlign="left">Clyde, NY 14433</EuiTextAlign>
              </EuiText>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiPanel>
              <EuiFlexGrid gutterSize="none" columns={1}>
                <EuiFlexItem>
                  <EuiText size="s">
                    <EuiTextAlign textAlign="left">
                      <b>Email:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                      <a href="mailto:info@wcicny.com">info@wcicny.com</a>
                    </EuiTextAlign>
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiText size="s">
                    <EuiTextAlign textAlign="left">
                      <b>Phone:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>(315) 923-2861
                    </EuiTextAlign>
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiText size="s">
                    <EuiTextAlign textAlign="left">
                      <b>Toll Free:&nbsp;&nbsp;</b>(800) 724-1648
                    </EuiTextAlign>
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGrid>
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer />

        <EuiPanel>
          <EuiTitle>
            <h3>Underwriting</h3>
          </EuiTitle>
          <EuiSpacer />
          <EuiTitle size="xs">
            <EuiTextColor color="success">
              <b>Team 1 - Personal Lines</b>
            </EuiTextColor>
          </EuiTitle>
          <EuiSpacer size="s" />
          <EuiBasicTable items={data.underwriting.teamOne} itemId="teamOne" columns={columns} />
          <EuiSpacer />
          <EuiTitle size="xs">
            <EuiTextColor color="success">
              <b>Team 2 - Personal Lines</b>
            </EuiTextColor>
          </EuiTitle>
          <EuiSpacer size="s" />
          <EuiBasicTable items={data.underwriting.teamTwo} itemId="teamTwo" columns={columns} />
          <EuiSpacer />
          <EuiTitle size="xs">
            <EuiTextColor color="success">
              <b>Farm and Commercial</b>
            </EuiTextColor>
          </EuiTitle>
          <EuiSpacer size="s" />
          <EuiBasicTable items={data.underwriting.farmAndCommercial} itemId="farmAndCommercial" columns={columns} />
        </EuiPanel>
        <EuiSpacer />
        <EuiPanel>
          <EuiTitle>
            <h3>Accounting</h3>
          </EuiTitle>
          <EuiSpacer />
          <EuiBasicTable items={data.accounting} itemId="accounting" columns={columns} />
        </EuiPanel>
        <EuiSpacer />
        <EuiPanel>
          <EuiTitle>
            <h3>Claims</h3>
          </EuiTitle>
          <EuiSpacer />
          <EuiBasicTable items={data.claims} itemId="claims" columns={columns} />
        </EuiPanel>
        <EuiSpacer />
        <EuiPanel>
          <EuiTitle>
            <h3>Other</h3>
          </EuiTitle>
          <EuiSpacer />
          <EuiBasicTable items={data.other} itemId="other" columns={columns} />
        </EuiPanel>
        <EuiSpacer />
        <EuiPanel>
          <EuiTitle>
            <h3>Executive</h3>
          </EuiTitle>
          <EuiSpacer />
          <EuiBasicTable items={data.executive} itemId="executive" columns={columns} />
        </EuiPanel>
        <EuiSpacer />
        <EuiPanel>
          <EuiTitle>
            <h3>Information Technology</h3>
          </EuiTitle>
          <EuiSpacer />
          <EuiBasicTable items={data.it} itemId="it" columns={columns} />
        </EuiPanel>
      </EuiPageBody>
    </EuiPage>
  );
};

export default Contact;
